import { render, staticRenderFns } from "./myAlerts.vue?vue&type=template&id=3c7d953d&scoped=true&"
import script from "./myAlerts.vue?vue&type=script&lang=js&"
export * from "./myAlerts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7d953d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AeqAlertsSettings: require('/home/vsts/work/1/s/components/AeqAlertsSettings.vue').default})
