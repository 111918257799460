
  import { mapState } from 'vuex'
  import { VueTelInputVuetify } from 'vue-tel-input-vuetify'

  export default {
    name: `ProfileSettings`,
    components: {
      VueTelInputVuetify
    },
    props: {
      value: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapState({
        first_name: (state) => state.settings.me.profile.first_name,
        last_name: (state) => state.settings.me.profile.last_name,
        gender: (state) => state.settings.me.profile.gender,
        phone: (state) => state.settings.me.profile.phone,
        mobile_phone: (state) => state.settings.me.profile.mobile_phone,
        job_title: (state) => state.settings.me.profile.job_title,
        language: (state) => state.settings.me.profile.language,
        client_roles_names: (state) => state.formData.settings.client_roles_names,
        role: (state) => state.settings.me.profile.role
      }),
      genders() {
        return [`F`, `M`].map((g) => ({
          text: this.$t(`components.genders.${g.toLocaleLowerCase()}`),
          value: g
        }))
      },
      langLocales() {
        return this.$helpers.languagesLocales()
      },
      email() {
        return this.$store.$auth.user.email
      }
    },
    methods: {
      async telInputChangeMobile(value, { number: { international }, isValid, country }) {
        if (value !== `` && !isValid) {
          return
        }
        await this.$store.commit(`settings/SET_VALUE`, {
          namespace: `profile`,
          key: `mobile_phone`,
          value: { ...this.mobile_phone, value: international || `` }
        })
      },
      async telInputChange(value, { number: { international }, isValid, country }) {
        if (value !== `` && !isValid) {
          return
        }
        await this.$store.commit(`settings/SET_VALUE`, {
          namespace: `profile`,
          key: `phone`,
          value: { ...this.mobile_phone, value: international || `` }
        })
      },
      async inputBlur(event, payload) {
        const newValue = event?.target?.value || ``
        await this.$store.commit(`settings/SET_VALUE`, {
          namespace: `profile`,
          key: `${payload.key}`,
          value: { ...payload.initial, value: newValue }
        })
      },
      async inputChange(newValue, payload) {
        !!newValue &&
          (await this.$store.commit(`settings/SET_VALUE`, {
            namespace: `profile`,
            key: `${payload.key}`,
            value: { ...payload.initial, value: newValue }
          }))
      },
      toUpperCase(event) {
        event.target.value = event.target.value.toUpperCase()
      },
      setErrors(errors) {
        if (!!errors) {
          this.$refs.profileObs.setErrors(errors)
        }
      }
    }
  }
