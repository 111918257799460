import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["componentType", "ref", "events"];
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.$fetchState.pending ? _c('v-overlay', {
    attrs: {
      "opacity": 0.2
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "width": 7,
      "color": "error",
      "indeterminate": "",
      "size": "70"
    }
  })], 1) : _vm.$fetchState.error ? _c('v-container', {
    staticClass: "screening-container pa-4 pa-sm-8",
    attrs: {
      "fluid": ""
    }
  }, [_c('h2'), _vm._v(" "), _c('v-row', {
    staticClass: "pa-3 mt-8 pt-8"
  }, [_c('v-alert', {
    staticClass: "mt-6 aeq-radius",
    attrs: {
      "type": "error",
      "border": "left",
      "min-width": "100%",
      "text": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pt-0",
    attrs: {
      "width": "100%"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('messages.error')) + "\n      ")]), _vm._v(" "), _c('v-card-text', [_vm._v(_vm._s(_vm.$t("messages.forbidden")))])], 1)], 1)], 1) : _c('div', [_c('PageHeader', {
    attrs: {
      "message": _vm.$t('pages.my_settings.header_message'),
      "title": _vm.$t('pages.my_settings.header_title')
    }
  }), _vm._v(" "), !_vm.error || !!_vm.error && _vm.error.status !== 403 ? _c('v-container', {
    staticClass: "screening-container pa-4 pa-sm-8",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-tabs', {
    attrs: {
      "background-color": "transparent",
      "centered": "",
      "icons-and-text": "",
      "show-arrows": "",
      "slider-size": "1"
    },
    model: {
      value: _vm.selectedTab,
      callback: function callback($$v) {
        _vm.selectedTab = $$v;
      },
      expression: "selectedTab"
    }
  }, _vm._l(_vm.filteredTabs, function (_ref, anchor) {
    var name = _ref.name,
      icon = _ref.icon;
    return _c('v-tab', {
      key: anchor,
      attrs: {
        "to": anchor,
        "exact": "",
        "nuxt-link": ""
      }
    }, [_vm._v("\n        " + _vm._s(_vm.$t(name)) + "\n        "), _c('v-icon', [_vm._v(_vm._s(icon))])], 1);
  }), 1), _vm._v(" "), _c('v-tabs-items', {
    staticClass: "my-8 pa-sm-8 pa-4",
    model: {
      value: _vm.selectedTab,
      callback: function callback($$v) {
        _vm.selectedTab = $$v;
      },
      expression: "selectedTab"
    }
  }, _vm._l(_vm.filteredTabs, function (_ref2, anchor) {
    var componentType = _ref2.componentType,
      ref = _ref2.ref,
      events = _ref2.events,
      props = _objectWithoutProperties(_ref2, _excluded);
    return _c('v-tab-item', {
      key: anchor,
      attrs: {
        "value": anchor
      }
    }, [_c('v-card', {
      staticClass: "pa-0 pa-sm-4 aeq-radius",
      attrs: {
        "color": "grey lighten-4",
        "flat": ""
      }
    }, [_c(componentType, _vm._g(_vm._b({
      ref: "currentComponent".concat(ref),
      refInFor: true,
      tag: "component"
    }, 'component', [events, props], false), events))], 1)], 1);
  }), 1), _vm._v(" "), _c('v-row', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-col', [_vm.selectedTab !== 'client-users' ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-btn', {
    staticClass: "aeq-radius colorButton--text letter-spacing-0 text-none ma-3",
    attrs: {
      "disabled": _vm.selectedTab === '#client-pricing' && !_vm.isMaster,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.updateProfile();
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('components.save')) + "\n          ")])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };