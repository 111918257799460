
  import { mapState } from 'vuex'
  import AeqAlertsSettings from '~/components/AeqAlertsSettings.vue'

  export default {
    name: `MyAlerts`,
    components: { AeqAlertsSettings },
    props: {
      value: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: true
      },
      withSpacer: {
        type: Boolean,
        default: false
      },
      withAlertSwitch: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        alerts: null,
        settings: {
          transparency: null,
          alerts: null,
          ...this.value
        },
        hasProgress: false,
        hasResult: false
      }
    },
    computed: {
      isMaster() {
        return this.$store.getters[`permissions/userIsMaster`]
      },
      ...mapState({
        communication_email: (state) =>
          state.settings.me.personal_dossiers_settings.absence_management.communication_email,
        email_in_cc_answers_list: (state) =>
          state.settings.me.personal_dossiers_settings.absence_management.email_in_cc_answers_list,
        email_in_cc: (state) => state.settings.me.personal_dossiers_settings.absence_management.email_in_cc
      }),
      candidateAlerts() {
        return this.settings.alerts.selected_alerts.filter((alert) => alert.code.includes(`employee_`))
      },
      otherAlerts() {
        return this.settings.alerts.selected_alerts.filter((alert) => alert.code.includes(`client_user_`))
      }
    },
    watch: {
      settings: {
        deep: true,
        handler() {
          this.$emit(`input`, this.settings)
        }
      },
      'value.communication_email': {
        deep: true,
        handler(newValue) {
          this.settings.communication_email = newValue
        }
      }
    },
    created() {
      if (this?.$attrs?.options?.value) {
        this.settings = this.$attrs.options.value()
      }
    },
    methods: {
      async inputBlurForAbsenceManagement(event, payload) {
        console.log(`inputBlurForAbsenceManagement`, event, payload)

        const value = typeof event === `string` ? event : event?.target?.value || ``
        await this.$store.commit(`settings/SET_VALUE_WITH_PARENT`, {
          namespace: `personal_dossiers_settings`,
          parent: `absence_management`,
          key: payload.key,
          value
        })
      },
      setErrors(errors) {
        if (!!errors) {
          const { alerts, transparency } = errors
          this.$refs.dossiersOptionsObs.setErrors({ alerts, ...transparency })
        }
      }
    }
  }
