
  import AeqAlertsSettings from '~/components/AeqAlertsSettings.vue'

  export default {
    name: `ScreeningsConfiguration`,
    components: { AeqAlertsSettings },
    props: {
      value: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: true
      },
      withSpacer: {
        type: Boolean,
        default: false
      },
      withAlertSwitch: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        alerts: null,
        settings: {
          transparency: null,
          alerts: null,
          ...this.value
        },
        hasProgress: false,
        hasResult: false
      }
    },
    computed: {
      isMaster() {
        return this.$store.getters[`permissions/userIsMaster`]
      },
      candidateAlerts() {
        return this.settings.alerts.selected_alerts.filter((alert) => alert.code.includes(`employee_`))
      },
      otherAlerts() {
        return this.settings.alerts.selected_alerts.filter((alert) => alert.code.includes(`client_user_`))
      }
    },
    watch: {
      settings: {
        deep: true,
        handler() {
          this.$emit(`input`, this.settings)
        }
      }
    },
    created() {
      if (this?.$attrs?.options?.value) {
        this.settings = this.$attrs.options.value()
      }
    },
    methods: {
      setErrors(errors) {
        if (!!errors) {
          const { alerts, transparency } = errors
          this.$refs.dossiersOptionsObs.setErrors({ alerts, ...transparency })
        }
      }
    }
  }
