
  export default {
    name: `AeqAlertsSettings`,
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      data: {
        type: [Array, Object],
        default: null
      },
      scrId: {
        type: String,
        default: undefined
      },
      mode: {
        type: String,
        default: `normal`
      },
      displayButtonValidate: {
        type: Boolean,
        default: false
      },
      filter: {
        type: Function,
        default: () => {}
      }
    },
    data() {
      return {
        alertsDialog: false,
        loading: false,
        alerts: null
      }
    },
    fetch() {
      if (!!this.data) {
        return null
      }

      const data =
        this.scrId !== undefined
          ? this.$api.alertConfiguration.getScreening(this.scrId)
          : this.$api.alertConfiguration.getClientUser()
      data
        .then((res) => {
          this.alerts = this.filter(res?.alerts)
          this.emitEvents(false)
        })
        .finally(() => {
          this.loading = false
        })
    },
    computed: {
      innerValue() {
        return this?.alerts?.length > 0 ? Object.fromEntries(this.alerts.map((a) => [a.name, a.answers])) : null
      }
    },
    watch: {
      data: {
        handler() {
          this.alerts = this.data
        },
        immediate: true
      }
    },
    mounted() {
      if (!!this.data) {
        this.alerts = this.data
        this.emitEvents(false)
      }
    },
    methods: {
      emitEvents(changeEvt = true, inputEvt = true) {
        if (changeEvt) {
          this.$emit(`change`, this.innerValue)
        }
        if (inputEvt) {
          this.$emit(`input`, this.innerValue)
        }
      },
      closeDialog(changeEvt = false) {
        if (changeEvt) {
          this.$emit(`change`, this.innerValue)
        }
        this.alertsDialog = false
      }
    }
  }
